<template>
  <div class="main">
    <Header :loading="loading" />
    <div class="container">
      <p
        class="is-size-4 has-text-weight-bold has-text-centered has-text-black"
      >
        {{ $t("room_occupancy") }}
      </p>
      <b-carousel
        class="rooms-carousel"
        :value="visibleRoom - 1"
        :arrow="arrow"
        :autoplay="autoplay"
        :indicator-background="indicatorBackground"
        :indicator-inside="indicatorInside"
        :indicator-mode="indicatorMode"
        :indicator-position="indicatorPosition"
        :indicator-style="indicatorStyle"
        @change="updateActiveRoom($event)"
      >
        <b-carousel-item>
          <ReadingRoom @reload="updateActiveRoomDetails" :loading="loading" />
        </b-carousel-item>

        <b-carousel-item>
          <AttachedRoom @reload="updateActiveRoomDetails" :loading="loading" />
        </b-carousel-item>

        <b-carousel-item>
          <MultimediaRoom @reload="updateActiveRoomDetails" :loading="loading" />
        </b-carousel-item>

        <template slot="indicators" slot-scope="props">
          <p class="is-size-4 has-text-weight-bold">
            {{ $t(`rooms.${props.i + 1}.name`) }}
          </p>
        </template>
      </b-carousel>
    </div>

    <Footer :class="{ shadow: visibleRoom === 1 }" />
  </div>
</template>

<script>
import Header from '@/components/Header'
import Footer from '@/components/Footer'
import ReadingRoom from '@/components/ReadingRoom'
import AttachedRoom from '@/components/AttachedRoom'
import MultimediaRoom from '@/components/MultimediaRoom'

export default {
  components: {
    Header,
    Footer,
    ReadingRoom,
    AttachedRoom,
    MultimediaRoom
  },

  data () {
    return {
      arrow: false,
      loading: false,
      autoplay: false,
      indicatorBackground: false,
      indicatorInside: true,
      indicatorMode: 'click',
      indicatorPosition: 'is-top',
      indicatorStyle: 'is-dots',
      interval: null,
      observeIndicators: null
    }
  },

  computed: {
    visibleRoom () {
      return this.$store.state.activeRoom
    },
    hasSession () {
      return this.$store.state.session.token
    }
  },

  mounted () {
    clearInterval(this.interval)
    this.updateActiveRoomDetails()
    this.interval = setInterval(() => this.updateActiveRoomDetails(), 60000)
  },

  beforeDestroy () {
    clearInterval(this.interval)
  },

  methods: {
    async updateActiveRoomDetails () {
      this.loading = true
      await this.$store.dispatch('loadRoomsData')
      if (this.hasSession) {
        await this.$store.dispatch('loadNotifications')
      }
      setTimeout(() => {
        this.loading = false
      }, 1000)
    },
    updateActiveRoom (value) {
      this.$store.dispatch('setActiveRoom', {
        room: value + 1
      })
      setTimeout(() => {
        document.querySelectorAll('.indicator-item')[value]
          .scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
      }, 150)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  padding-bottom: 73px;
  width: 100%;
}
</style>
