var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('section',{staticClass:"reading-room",class:{ 'is-loading': _vm.loading },attrs:{"draggable":"true"}},[_c('div',{staticClass:"main-title",class:{ 'has-text-black': _vm.room.free_seats === 0, },on:{"click":function($event){return _vm.$emit('reload')}}},[_c('span',{staticClass:"spacer"}),_c('span',{staticClass:"counter"},[_vm._v(_vm._s(_vm.room.free_seats))]),_vm._m(0)]),_c('div',{staticClass:"main-legend mb-3",class:{ 'has-text-black': _vm.room.free_seats === 0 }},[_vm._v(_vm._s(_vm.$t("free_seats")))]),_c('div',{staticClass:"seats"},[_c('div',{staticClass:"seats-row"},_vm._l((6),function(i){return _c('div',{key:i,staticClass:"seat rounded bottom-chair",class:{ free: _vm.free(i + 104) },attrs:{"data-seat-id":("" + (i + 104))}})}),0),_c('div',{staticClass:"seats-center-row"},[_c('div',{staticClass:"seats-container"},_vm._l((48),function(i){return _c('div',{key:i,staticClass:"seat",class:{
            'top-rounded': _vm.topRounded(i, 48),
            'left-chair': _vm.leftChair(i, 48),
            free: _vm.free(i),
            'ml-4': _vm.spaceSeat(i),
          },attrs:{"data-seat-id":("" + i)}})}),0),_c('div',{staticClass:"seats-container"},_vm._l((12),function(i){return _c('div',{key:i,staticClass:"seat",class:{
            'top-rounded': _vm.inRange(i, 1, 6),
            'left-chair': _vm.inRange(i, 1, 3) || _vm.inRange(i, 7, 9),
            free: _vm.free(i + 48),
            'ml-4': [4, 10].includes(i),
          },attrs:{"data-seat-id":("" + (i + 48))}})}),0),_c('div',{staticClass:"seats-container"},_vm._l((32),function(i){return _c('div',{key:i,staticClass:"seat",class:{
            'top-rounded': _vm.topRounded(i, 32),
            'left-chair': _vm.leftChair(i, 32),
            free: _vm.free(i + 60),
            'ml-4': _vm.spaceSeat(i),
          },attrs:{"data-seat-id":("" + (i + 60))}})}),0),_c('div',{staticClass:"seats-container"},_vm._l((12),function(i){return _c('div',{key:i,staticClass:"seat",class:{
            'top-rounded': _vm.inRange(i, 1, 6),
            'left-chair': _vm.inRange(i, 1, 3) || _vm.inRange(i, 7, 9),
            free: _vm.free(i + 92),
            'ml-4': [4, 10].includes(i),
          },attrs:{"data-seat-id":("" + (i + 92))}})}),0)]),_c('div',{staticClass:"seats-row"},_vm._l((6),function(i){return _c('div',{key:i,staticClass:"seat rounded bottom-chair",class:{ free: _vm.free(i + 110) },attrs:{"data-seat-id":("" + (i + 110))}})}),0),_c('div',{staticClass:"has-drag",attrs:{"draggable":"true"}})])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{staticClass:"spacer"},[_c('img',{staticClass:"icon",attrs:{"alt":"Reload","src":require("../assets/sync-solid.svg")}})])}]

export { render, staticRenderFns }