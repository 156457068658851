<template>
  <b-navbar class="Header" :mobile-burger="false" :spaced="true">
    <template slot="brand">
      <b-navbar-item tag="router-link" :to="{ path: '/' }">
        <img alt="logo" src="../assets/logo.svg" />
      </b-navbar-item>
      <div v-if="loading" class="spinner">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </template>

    <template slot="end">
      <b-navbar-item tag="div" class="pr-0">
        <LanguageSelect class="has-margin-right" />
        <router-link
          :to="{ path: 'notifications' }"
          class="button is-small is-primary has-text-white"
          v-if="$store.state.notifications.length"
        >
          <fa size="lg" icon="bell"></fa>
          <span class="is-size-6 ml-2">{{ $store.state.notifications.length }}</span>
        </router-link>
      </b-navbar-item>
    </template>
  </b-navbar>
</template>

<script>
import LanguageSelect from '@/components/LanguageSelect'

export default {
  name: 'Header',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  components: {
    LanguageSelect
  }
}
</script>
