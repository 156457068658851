<template>
  <section draggable="true" class="attached-room" :class="{ 'is-loading': loading }">
    <div class="main-title" @click="$emit('reload')" :class="{ 'has-text-black': room.free_seats === 0, }">
      <span class="spacer"></span>
      <span class="counter">{{ room.free_seats }}</span>
      <span class="spacer">
        <img class="icon" alt="Reload" src="../assets/sync-solid.svg" />
      </span>
    </div>
    <div :class="{ 'has-text-black': room.free_seats === 0 }" class="main-legend mb-3">{{ $t("free_seats") }}</div>

    <div class="seats-container">
      <div
        v-for="i in 24"
        :key="i"
        class="seat"
        :class="{
          'top-rounded': topRounded(i),
          'left-chair': leftChair(i),
          free: free(i),
        }"
        :data-seat-id="`${i}`"
      ></div>
      <div class="has-drag" draggable="true"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AttachedRoom',
  data () {
    return {
      seatsMap: {
        24: '1',
        23: '5',
        22: '9',
        21: '13',
        20: '17',
        19: '21',
        18: '2',
        17: '6',
        16: '10',
        15: '14',
        14: '18',
        13: '22',
        12: '3',
        11: '7',
        10: '11',
        9: '15',
        8: '19',
        7: '23',
        6: '4',
        5: '8',
        4: '12',
        3: '16',
        2: '20',
        1: '24'
      }
    }
  },
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    room () {
      return this.$store.state.attachedRoom
    }
  },
  methods: {
    free (value) {
      if (!this.room.seats) return
      const el = this.room.seats.find((element) => element.key === this.seatsMap[value])
      if (el && el.free && el.available) {
        return true
      }
      return false
    },
    // Every other 6 seats have rounded top
    topRounded (value) {
      let rounded = false
      for (let i = 6; i <= 24; i += 12) {
        rounded = rounded || this.inRange(value, i - 5, i)

        if (rounded === true) {
          return true
        }
      }
      return false
    },
    // All seats with chairs on the left have a odd number as seat-id
    leftChair (value) {
      if (value % 2 !== 0) {
        return true
      }
      return false
    },
    inRange (value, min, max) {
      return (value - min) * (value - max) <= 0
    }
  }
}
</script>
