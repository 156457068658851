<template>
  <section draggable="true" class="multimedia-room" :class="{ 'is-loading': loading }">
    <div class="main-title" @click="$emit('reload')" :class="{ 'has-text-black': room.free_seats === 0, }">
      <span class="spacer"></span>
      <span class="counter">{{ room.free_seats }}</span>
      <span class="spacer">
        <img class="icon" alt="Reload" src="../assets/sync-solid.svg" />
      </span>
    </div>
    <div :class="{ 'has-text-black': room.free_seats === 0 }" class="main-legend mb-3">{{ $t("free_seats") }}</div>

    <div class="seats">
      <div class="seats-row">
        <div class="seat top-chair force-rounded" data-seat-id="157" :class="{ free: free(157) }"></div>
        <div class="seat right-chair force-rounded" data-seat-id="156" :class="{ free: free(156) }"></div>
        <div class="seat right-chair force-rounded" data-seat-id="155" :class="{ free: free(155) }"></div>
        <div class="seat right-chair force-rounded" data-seat-id="154" :class="{ free: free(154) }"></div>
        <div class="seat bottom-chair force-rounded" data-seat-id="153" :class="{ free: free(153) }"></div>
      </div>
      <div class="seats-center-row">
        <div class="seats-container">
          <div class="seat bottom-chair force-rounded" data-seat-id="147" :class="{ free: free(147) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="148" :class="{ free: free(148) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="149" :class="{ free: free(149) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="144" :class="{ free: free(144) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="145" :class="{ free: free(145) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="146" :class="{ free: free(146) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="141" :class="{ free: free(141) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="142" :class="{ free: free(142) }"></div>
          <div class="seat bottom-chair force-rounded" data-seat-id="143" :class="{ free: free(143) }"></div>
        </div>
      </div>
      <div class="seats-row">
        <div class="seat left-chair force-rounded mb-5" data-seat-id="152" :class="{ free: free(152) }"></div>
        <div class="seat left-chair force-rounded mb-5" data-seat-id="151" :class="{ free: free(151) }"></div>
        <div class="seat left-chair force-rounded" data-seat-id="150" :class="{ free: free(150) }"></div>
      </div>
      <div class="has-drag" draggable="true"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'AttachedRoom',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    room () {
      return this.$store.state.multimediaRoom
    }
  },
  methods: {
    free (value) {
      if (!this.room.seats) return
      const el = this.room.seats.find((element) => element.key === value.toString())
      if (el && el.free && el.available) {
        return true
      }
      return false
    }
  }
}
</script>
