<template>
  <section draggable="true" class="reading-room" :class="{ 'is-loading': loading }">
    <div class="main-title" @click="$emit('reload')" :class="{ 'has-text-black': room.free_seats === 0, }">
      <span class="spacer"></span>
      <span class="counter">{{ room.free_seats }}</span>
      <span class="spacer">
        <img class="icon" alt="Reload" src="../assets/sync-solid.svg" />
      </span>
    </div>
    <div :class="{ 'has-text-black': room.free_seats === 0 }" class="main-legend mb-3">{{ $t("free_seats") }}</div>

    <div class="seats">
      <div class="seats-row">
        <!-- 6 seats / seat ids from 105 to 110 -->
        <div
          class="seat rounded bottom-chair"
          v-for="i in 6"
          :key="i"
          :class="{ free: free(i + 104) }"
          :data-seat-id="`${i + 104}`"
        ></div>
      </div>

      <div class="seats-center-row">
        <div class="seats-container">
          <!-- 48 seats / seat ids from 1 to 48 -->
          <div
            v-for="i in 48"
            :key="i"
            class="seat"
            :class="{
              'top-rounded': topRounded(i, 48),
              'left-chair': leftChair(i, 48),
              free: free(i),
              'ml-4': spaceSeat(i),
            }"
            :data-seat-id="`${i}`"
          ></div>
        </div>
        <div class="seats-container">
          <!-- 12 seats / seat ids from 49 to 60 -->
          <div
            v-for="i in 12"
            :key="i"
            class="seat"
            :class="{
              'top-rounded': inRange(i, 1, 6),
              'left-chair': inRange(i, 1, 3) || inRange(i, 7, 9),
              free: free(i + 48),
              'ml-4': [4, 10].includes(i),
            }"
            :data-seat-id="`${i + 48}`"
          ></div>
        </div>
        <div class="seats-container">
          <!-- 32 seats / seat ids from 61 to 92 -->
          <div
            v-for="i in 32"
            :key="i"
            class="seat"
            :class="{
              'top-rounded': topRounded(i, 32),
              'left-chair': leftChair(i, 32),
              free: free(i + 60),
              'ml-4': spaceSeat(i),
            }"
            :data-seat-id="`${i + 60}`"
          ></div>
        </div>
        <div class="seats-container">
          <!-- 12 seats / seat ids from 93 to 104 -->
          <div
            v-for="i in 12"
            :key="i"
            class="seat"
            :class="{
              'top-rounded': inRange(i, 1, 6),
              'left-chair': inRange(i, 1, 3) || inRange(i, 7, 9),
              free: free(i + 92),
              'ml-4': [4, 10].includes(i),
            }"
            :data-seat-id="`${i + 92}`"
          ></div>
        </div>
      </div>

      <div class="seats-row">
        <!-- 6 seats / seat ids from 111 to 116 -->
        <div
          class="seat rounded bottom-chair"
          v-for="i in 6"
          :key="i"
          :class="{ free: free(i + 110) }"
          :data-seat-id="`${i + 110}`"
        ></div>
      </div>
      <div class="has-drag" draggable="true"></div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'ReadingRoom',
  props: {
    loading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      seatsMap: {
        1: '37',
        2: '57',
        3: '85',
        4: '113',
        5: '114',
        6: '86',
        7: '58',
        8: '38',

        9: '39',
        10: '59',
        11: '87',
        12: '115',
        13: '116',
        14: '88',
        15: '60',
        16: '40',

        17: '41',
        18: '61',
        19: '89',
        20: '117',
        21: '118',
        22: '90',
        23: '62',
        24: '42',

        25: '43',
        26: '63',
        27: '91',
        28: '119',
        29: '120',
        30: '92',
        31: '64',
        32: '44',

        33: '45',
        34: '65',
        35: '93',
        36: '121',
        37: '122',
        38: '94',
        39: '66',
        40: '46',

        41: '47',
        42: '67',
        43: '95',
        44: '123',
        45: '124',
        46: '96',
        47: '68',
        48: '48',

        49: '69',
        50: '97',
        51: '125',
        52: '126',
        53: '98',
        54: '70',

        55: '71',
        56: '99',
        57: '127',
        58: '128',
        59: '100',
        60: '72',

        61: '49',
        62: '73',
        63: '101',
        64: '129',
        65: '130',
        66: '102',
        67: '74',
        68: '50',

        69: '51',
        70: '75',
        71: '103',
        72: '131',
        73: '132',
        74: '104',
        75: '76',
        76: '52',

        77: '53',
        78: '77',
        79: '105',
        80: '133',
        81: '134',
        82: '106',
        83: '78',
        84: '54',

        85: '55',
        86: '79',
        87: '107',
        88: '135',
        89: '136',
        90: '108',
        91: '80',
        92: '56',

        93: '81',
        94: '109',
        95: '137',
        96: '138',
        97: '110',
        98: '82',

        99: '83',
        100: '111',
        101: '139',
        102: '140',
        103: '112',
        104: '84',

        105: '25',
        106: '27',
        107: '29',
        108: '31',
        109: '33',
        110: '35',

        111: '26',
        112: '28',
        113: '30',
        114: '32',
        115: '34',
        116: '36'
      }
    }
  },
  computed: {
    room () {
      return this.$store.state.readingRoom
    }
  },
  methods: {
    free (value) {
      if (!this.room.seats) return
      const el = this.room.seats.find((element) => element.key === this.seatsMap[value])
      if (el && el.free && el.available) {
        return true
      }
      return false
    },
    // Maintains middle collumn spacing
    spaceSeat (value) {
      return [5, 13, 21, 29, 37, 45].includes(value)
    },
    // Every other 16 seats are rounded on top
    topRounded (value, end) {
      let rounded = false
      for (let i = 8; i <= end; i += 16) {
        rounded = rounded || this.inRange(value, i - 7, i)

        if (rounded === true) {
          return true
        }
      }
      return false
    },
    // Every other 8 seats have chairs on the left
    leftChair (value, end) {
      let left = false
      for (let i = 4; i <= end; i += 8) {
        left = left || this.inRange(value, i - 3, i)

        if (left === true) {
          return true
        }
      }
      return false
    },
    inRange (value, min, max) {
      return (value - min) * (value - max) <= 0
    }
  }
}
</script>
