<template>
  <b-dropdown
    aria-role="list"
    v-model="$i18n.locale"
    position="is-bottom-left"
    @change="updateLanguage($event)"
  >
    <button class="button is-text" slot="trigger" slot-scope="{ active }">
      <fa class="is-size-5" icon="globe-europe"></fa>
      <span class="icon"
        ><img src="../assets/arrow-down.svg" :class="{ 'rotate-180': active }"
      /></span>
    </button>
    <b-dropdown-item value="pt" aria-role="listitem">Português</b-dropdown-item>
    <b-dropdown-item value="en" aria-role="listitem">English</b-dropdown-item>
  </b-dropdown>
</template>

<script>
export default {
  name: 'LanguageSelect',
  methods: {
    updateLanguage (locale) {
      localStorage.setItem('language', locale)
    }
  }
}
</script>
